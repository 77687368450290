.custom-checkbox {
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid #151b1b;
  border-radius: 5px;
}
.checkmark img {
  display: none;
}
.custom-checkbox input:checked ~ .checkmark img {
  display: block;
}
.custom-checkbox input:checked ~ .checkmark {
  border-color: #4dc2c1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
