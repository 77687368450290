html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  @apply font-montserrat;
}
textarea {
  @apply p-3 border border-gray-300 focus-within:border-gray-400 resize-none rounded-xl outline-none;
}
.optionContainer li.option:hover,
.optionContainer .highlight {
  background: #4dc2c1;
  color: #fff;
}

@media (max-width: 640px) {
  :root {
    font-size: 14px;
  }
}

*::-webkit-scrollbar {
  @apply w-1 h-1 bg-lighter-tint/50 rounded-full;
}
*::-webkit-scrollbar-thumb {
  @apply bg-dark-teal;
}

input:disabled {
  color: #57585a;
  cursor: not-allowed;
}
button {
  @apply disabled:text-dark disabled:bg-gray-200 disabled:cursor-not-allowed;
}
.term {
  h1 {
    font-size: 26px;
  }
}

.loading {
  height: 1rem;
  width: 100%;
  margin: 0 1rem;
  border: 2px solid #4dc2c1;
  border-radius: 20px;
  background: repeating-linear-gradient(300deg, #4dc2c1, #4dc2c1 10px, white 10px, white 20px);
  position: relative;
}

.loading:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: repeating-linear-gradient(300deg, #4dc2c1, #4dc2c1 10px, white 10px, white 20px);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
  0% {
    transform: translateX(0%);
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.landing-page-action {
  @apply w-[9.5rem] cursor-pointer text-sm;
}
.multiselect-container .option:hover {
  background-color: #f9fbfb !important;
  color: #151b1b !important;
}
.multiselect-container .option {
  background-color: transparent !important;
  color: #151b1b !important;
}
.multiselect-container,
.searchWrapper {
  border-radius: 16px !important;
}
//#575d5f
